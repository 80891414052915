var InformationCommon = {
  showLoadingIcon() {
    $('.loadingImage').show()
  },
  hideLoadingIcon() {
    $('.loadingImage').hide()
  },
  getBetsudaiNewsAndEvents(page, number_page, sort_by, order) {
    $.ajax({
      url: '/betsudai_news_and_events',
      type: 'GET',
      dataType: 'json',
      data: { page: page, number_page: number_page, sort_by: sort_by, order: order },
      success: (data) => {
        InformationCommon.hideLoadingIcon()
        if (data.list_news) {
          const hasNewBrand = data.list_news.has_new_brand ? 'new': ''
          const hasNewCatalog = data.list_news.has_new_catalog ? 'new': ''
          const hasNewInformation = data.list_news.has_new_information ? 'new': ''
          const hasNewMagazine = data.list_news.has_new_magazine ? 'new': ''
          const hasNewSale = data.list_news.has_new_sale ? 'new': ''
          const hasNewModelHouse = data.list_news.has_new_modelhouse ? 'new': ''
          $('#NavWrap li.has_new_brand').addClass(hasNewBrand)
          $('#NavWrap li.has_new_catalog').addClass(hasNewCatalog)
          $('#NavWrap li.has_new_information').addClass(hasNewInformation)
          $('#NavWrap li.has_new_magazine').addClass(hasNewMagazine)
          $('#NavWrap li.has_new_sale').addClass(hasNewSale)
          $('#NavWrap li.has_new_modelhouse').addClass(hasNewModelHouse)
        }
        $('.informationContent').html(data.html)
        $('.pager').html(data.pagination)
        window.lazyLoadInstance.update()
      },
      error: function(XMLHttpRequest, textStatus, errorThrown) {
        console.log('ERROR')
      }
    });
  },
  getBetsudaiNews(page, number_page, sort_by, order, category) {
    $.ajax({
      url: '/betsudai_news',
      type: 'GET',
      dataType: 'json',
      data: { page: page, number_page: number_page, sort_by: sort_by, order: order, category: category },
      success: function(data) {
        if (data.no_post) {
          $('section.Media').hide()
        } else {
          InformationCommon.hideLoadingIcon()
          $('.Media__list').html(data.html)
          $('.pager').html(data.pagination)
          window.lazyLoadInstance.update()
        }
      },
      error: function(XMLHttpRequest, textStatus, errorThrown) {
        console.log('ERROR')
      }
    });
  },
  getEvents(page, number_page) {
    $.ajax({
      url: '/events',
      type: 'GET',
      dataType: 'json',
      data: { page: page, number_page: number_page },
      success: function(data) {
        InformationCommon.hideLoadingIcon()
        $('.informationContent').html(data.html)
        $('.pager').html(data.pagination)
        window.lazyLoadInstance.update()
      },
      error: function(XMLHttpRequest, textStatus, errorThrown) {
        console.log('ERROR')
      }
    });
  },
}

export default InformationCommon
