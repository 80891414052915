import CatalogStarterContact from "./catalog_starter_contact";
import {ValidationForm} from "../../src/web/validation_form";

const STARTER_KEY = 'starter_contact'

var Starter = {
    init() {
        this.validateStarter()
        this.removeContactFields()
        CatalogStarterContact.setAttributesContact('#starter_confirm',STARTER_KEY)
        CatalogStarterContact.fillContactFields(STARTER_KEY)
    },
    removeContactFields () {
        $('#submit_starter').on('click', function () {
            localStorage.removeItem('starter_contact')
            localStorage.removeItem('contact_address')
        })
    },
    validateStarter () {
        $('#starter_confirm').on('click', function (e) {
            if (!Starter.isValid()) {
                $('html, body').animate({
                    scrollTop: $('#formWrap').offset().top
                }, 500);

                e.preventDefault();
                return false
            }
        })
    },
    isValid () {
        return CatalogStarterContact.isValidStarterCatalogContact()
    },
}

export default Starter
