import { ValidationForm } from '../../src/web/validation_form'

var Reservation = {
  init() {
    this.handleValidateFormReservation()
    this.saveAddress()
  },
  handleValidateFormReservation () {
    $('.btn-validate-form-reservation').on('click', function() {
      const ValidateForm = new ValidationForm()
      const isInputValid = ValidateForm.handleValidateInputForm($('.required'))
      const isInputValid2 = ValidateForm.handleValidateInputForm2($('.required2'))
      const isInputSelectValid = ValidateForm.handleValidateInputSelectForm($('.required-select'))
      const isInputAddressValid = ValidateForm.validateAddress($('#reservation_address'), $('.templory-address-reservation'))
      const isValidItem = ValidateForm.handleValidateCheckboxForm(
        $('.reservation-items').find('input:radio')
      )
      const isValidContactWithStaff = ValidateForm.handleValidateCheckboxForm(
        $('.reservation-contact-with-staff').find('input:radio')
      )
      const isValidCkWayFindOutEvents = ValidateForm.handleValidateCheckboxForm(
        $('.way-find_out-events').find('input:checkbox')
      )
      const isValidPhone = ValidateForm.handleValidatePhone()
      const isValidEmail = ValidateForm.handleValidateEmail()
      const isValidZip = ValidateForm.handleValidateZipcode()
      const isValidFurigana = ValidateForm.handleValidateFurigana()
      const acceptPolicy = ValidateForm.handleValidateAcceptPolicy()
      const isValidLocationName = ValidateForm.handleValidateInputSelectForm(
        $('.select-location:first-child').find('.required-select-location')
      )

      const isActive = isInputValid && isInputValid2 && isInputSelectValid && isValidItem
                        && isValidContactWithStaff && isValidCkWayFindOutEvents
                        && acceptPolicy && isValidEmail && isValidZip && isValidPhone
                        && isValidLocationName && isValidFurigana && isInputAddressValid
      if (isActive == true) {
        $('#reservation').submit()
      } else {
        $('html, body').animate({
          scrollTop: $('.validate-form').offset().top
        }, 500);
      }
    })
  },
  saveAddress () {
    const ValidateForm = new ValidationForm()
    ValidateForm.saveAddress($('.templory-address-reservation'), $('#reservation_address'))
  }
}

export default Reservation
