var Magazine = {
  init: function () {
    this.showLoadingIcon()
    this.hideLoadingIcon()
    this.loadAllTags()
    this.searchMagazine()
    this.openSelectCategory()
    this.openSelectTag()
    $('#category, #tag').select2({
      minimumResultsForSearch: -1
    })

    window.lazyLoadInstance.update()
  },
  showLoadingIcon() {
    $('.loadingImage').show()
  },
  hideLoadingIcon() {
    $('.loadingImage').hide()
    $('.loading-tag').hide()
  },


  loadAllTags: function() {
    $('#loadTags').on('click', function (e) {
      $('.loading-tag').show()
      Magazine.getTags()
      $('.tag').css('max-height','none');
    })
  },

  getTags: function (perPage) {
    $.ajax({
      url: '/magazines/generate_tags_list',
      type: 'GET',
      dataType: 'json',
      data: { per_page: perPage, selected_only: true },
      success: (data) => {
        $('.loading-tag').hide()
        $('.links-keywords__list').html(data.html)
      },
      error: function(XMLHttpRequest, textStatus, errorThrown) {
        console.log('ERROR')
      }
    });
  },

  searchMagazine: function () {
    $('#searchMagazine').on('click', function() {
      const category = $('#category option:selected').val()
      const tag = $('#tag option:selected').val()
      window.location.href = `/magazines/lifestyle?category=${category}&tag=${tag}`
    })
  },

  openSelectCategory: function () {
    $('.icon-select-category').on('click', function () {
      $('#category').select2('open')
    })
  },

  openSelectTag: function () {
    $('.icon-select-tag').on('click', function () {
      $('#tag').select2('open')
    })
  }
}

export default Magazine
