// ウィンドウサイズ変更時 リロード PCのみ
import {Swiper, Pagination, Navigation} from 'swiper'

(function ($) {
  if(!navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)){

    var timer = 0;
    var currentWidth = window.innerWidth;
    $(window).resize(function(){
        if (currentWidth == window.innerWidth) {
            return;
        }
        if (timer > 0) {
            clearTimeout(timer);
        }

        timer = setTimeout(function () {
            location.reload();
        }, 0);
    });

  }

  // GoogleMap Scroll Control
  $(function() {
    var map = $('iframe');
    //あらかじめiframeにpointer-events:noneを掛け、マウスイベントを無効にしておく
    map.css('pointer-events', 'none');
    //一度クリックされたらマウスイベントを有効にする
    $('.gmap').click(function() {
      map.css('pointer-events', 'auto');
    });
    //iframeからマウスが離れたら再度pointer-events:noneを効かせる
    map.mouseout(function() {
      map.css('pointer-events', 'none');
    });
  })

  // fade
  $(function(){
    function animation(){
      //$('main > section:not(:first-child):not(.pNav):not(.Image):not(.Name), main section:not(.pNav):not(.Image):not(.Name) > .inner > *, main section:not(.pNav):not(.Image):not(.Name) > .inner > .wrap > *').each(function(){
      $('main > *:not(:first-child):not(.pNav + *):not(.uNav + *):not(.totalFix), main section:not(:first-child):not(.pNav + *):not(.totalFix) > .inner > *:not(.modalSwiper), .bgWrap > section:not(:first-child)').each(function(){
        var target = $(this).offset().top;
        var scroll = $(window).scrollTop();
        var windowHeight = $(window).height();
        if (scroll > target - windowHeight){
          $(this).css('opacity','1');
          $(this).css('transform','translateY(0)');
        }
      });
    }
    animation();
    $(window).scroll(function (){
      animation();
    });
  });

  // 追従 ナビ
  $(function () {
    var header = $('header');
    var lastScrollTop = 0;
    var isTopPage = window.location.pathname === "/";

    if (isTopPage) {
      if ($(window).width() >= 1190) {
        header.css("position", "fixed");
      }
    }

    $(window).scroll(function () {
      var scrollTop = $(window).scrollTop();

      if (isTopPage) {
        // TOPページの場合
        if (scrollTop > 190) {
          header.addClass('mini');
        } else {
          header.removeClass('mini');
        }
      }

      lastScrollTop = scrollTop;
    });
  });

  // $(function () {
  //   var headNav = $("header");
  //   //scrollだけだと読み込み時困るのでloadも追加
  //   $(window).on('load scroll', function () {
  //     //現在の位置が150px以上かつ、クラスfixedが付与されていない時
  //     if ($(this).scrollTop() > 150 && headNav.hasClass('fixed') == false) {
  //       //headerの高さ分上に設定
  //       headNav.css({
  //         "top": '-100px'
  //       });
  //       //クラスfixedを付与
  //       headNav.addClass('fixed');
  //       //位置を0に設定し、アニメーションのスピードを指定
  //       headNav.animate({
  //         "top": 0
  //       }, 500);
  //     }
  //     //現在の位置が300px以下かつ、クラスfixedが付与されている時にfixedを外す
  //     else if ($(this).scrollTop() < 150 && headNav.hasClass('fixed') == true) {
  //       headNav.removeClass('fixed');
  //     }
  //   });
  // });

  $(function () {
    var headNav = $(".pNav");
    //scrollだけだと読み込み時困るのでloadも追加
    $(window).on('load scroll', function () {
      //現在の位置が80px以上かつ、クラスfixedが付与されていない時
      if ($(this).scrollTop() > 74 && headNav.hasClass('fixed') == false) {
        //headerの高さ分上に設定
        headNav.css({
          //"top": '-100px'
        });
        //クラスfixedを付与
        headNav.addClass('fixed');
        //位置を0に設定し、アニメーションのスピードを指定
        headNav.animate({
          "top": 0
        }, 500);
      }
      //現在の位置が300px以下かつ、クラスfixedが付与されている時にfixedを外す
      else if ($(this).scrollTop() < 74 && headNav.hasClass('fixed') == true) {
        headNav.removeClass('fixed');
      }
    });
  });

  $(function () {
    var headNav = $("#NavWrapSP");
    //scrollだけだと読み込み時困るのでloadも追加
    $(window).on('load scroll', function () {
      //現在の位置が700px以上かつ、クラスfixedが付与されていない時
      if ($(this).scrollTop() > 700 && headNav.hasClass('fixed') == false) {
        //headerの高さ分上に設定
        headNav.css({
          //"top": '-100px'
        });
        //クラスfixedを付与
        headNav.addClass('fixed');
        //位置を0に設定し、アニメーションのスピードを指定
        headNav.animate({
          "bottom": 0
        }, 500);
      }
      //現在の位置が300px以下かつ、クラスfixedが付与されている時にfixedを外す
      else if ($(this).scrollTop() < 700 && headNav.hasClass('fixed') == true) {
        headNav.removeClass('fixed');
      }
    });
  });


  // スムーズスクロール
  $(function () {
    // #で始まるアンカーをクリックした場合に処理
    $('a[href^="#"]').click(function () {
      if ($(this).attr('data-no-scroll') == 'true') {
        return;
      }
      // 移動先を0px調整する。0を30にすると30px下にずらすことができる。
      var adjust = 0;
      // スクロールの速度
      var speed = 400; // ミリ秒
      // アンカーの値取得
      var href = $(this).attr("href");
      // 移動先を取得
      var target = $(href == "#" || href == "" ? 'html' : href);
      // 移動先を調整
      var position = target.offset().top + adjust;
      // スムーススクロール
      $('body,html').animate({
        scrollTop: position
      }, speed, 'swing');
      return false;
    });
  });


  // アクティブ
  $(function(){
  //  $('#NavWrap .current').removeClass('current');
  //  $('#NavWrap a').filter(function(){return $(this).prop('href')==location.href;}).addClass('current');
    let path = location.href.split("?")[0]
    $('.List .display .current').removeClass('current');
    $('.List .display a').filter(function(){return $(this).prop('href').split("?")[0] == path;}).addClass('current');

    $('.pNav .current').removeClass('current');
    $('.pNav a').filter(function(){return $(this).prop('href').split("?")[0] == path;}).addClass('current');

    $('.uNav .current').removeClass('current');
    $('.uNav a').filter(function(){return $(this).prop('href').split("?")[0] == path;}).addClass('current');
  });


  //// 上へ戻る
  //$(function () {
  //  var topBtn = $('.totop');
  //  topBtn.hide();
  //  //スクロールが数値に達したらボタン表示
  //  $(window).scroll(function () {
  //    if ($(this).scrollTop() > 1000) {
  //      //ボタンの表示方法
  //      topBtn.fadeIn();
  //    } else {
  //      //ボタンの非表示方法
  //      topBtn.fadeOut();
  //    }
  //  });
  //});


  //アコーディオン
  $(function(){
    $(".acc dt, .acc > .btn, .acc .accBtn").on("click", function() {
      /*クリックでコンテンツを開閉*/
      $(this).next().slideToggle(300);
      /*矢印の向きを変更*/
      $(this).toggleClass('open');
    });
  });

  if (window.matchMedia( "(max-width: 599px)" ).matches) {
    /* ウィンドウサイズが 599px以下の場合のコードをここに */

    $(function(){
      $(".Product .pNav .headWrap").on("click", function() {
        /*クリックでコンテンツを開閉*/
        $(this).next().slideToggle(300);
        /*矢印の向きを変更*/
        $(this).toggleClass('open');
      });

      //メニューがクリックされたら
      $('.Product .pNav nav a').click(function () {
        //クリックされたa.close_btnの親要素.accordion_innerを閉じる。
        $(this).parents('nav').slideUp();
        //クリックされたa.close_btnの親要素.accordion_innerの前要素にクラスopenがなければ追加し、あれば削除する。
        $(this).parents('nav').prev().toggleClass("open");
      });
    });

  }

  //スタッフ紹介
  $(function(){
    $(".profile .thumb").on("click", function() {
      /*クリックでコンテンツを開閉*/
      $(this).next().slideToggle(300);
      $(".thumb").not($(this)).siblings(".cont").slideUp();
    });

    $(".profile .close").on("click", function() {
      $(this).parents(".cont").slideUp();
    });
  });
  $(function(){
    $(".profile .thumb .img").mouseover(function(){
      $(this).children('img:first').attr("src", $(this).children('span:last').html())
    }).mouseout(function(){
      $(this).children('img:first').attr("src", $(this).children('span:first').html())
    })
  });


  // スマホメニュー
  $(function () {
    var $nav = $('#NavWrap');
    var $btn = $('.Toggle');
    var $mask = $('#Mask');
    var open = 'open'; // class
    // menu open close
    $btn.on('click', function () {
      if (!$nav.hasClass(open)) {
        $nav.addClass(open);
      } else {
        $nav.removeClass(open);
      }
    });

    // mask close
    $mask.on('click', function () {
      $nav.removeClass(open);
    });

    $('#NavWrap a').on('click', function () {
      $('#NavWrap').removeClass('open');
    })
  });

  $(function () {
    $('#NavWrap .Nav > ul > li > *').on('click', function () {
      $(this).next().slideToggle();

      $('#NavWrap .Nav > ul > li > *').not(this).removeClass('open');
      //クリックした要素以外の全てのopenを取る

      $(this).toggleClass('open');
      //thisにopenクラスを付与
    });
  });


  // 高さ調整
  $(window).on('load', function() {
    var height1 = $(".ul3 li .img img").innerHeight() || 0;
    var height2 = $(".ul3 li .imgSub img").innerHeight() || 0;
    $(".ul3 li .fav").css('top', height1+height2+'px');
  });
  //$(function() {
  //  $('.Kikaku .Compare h3').matchHeight();
  //});


  // slick
  $(function () {
    $('.fWrap .fBnrS .slide').slick({
      speed: 1000,
      autoplaySpeed: 6000,
      arrows: true,
      autoplay: true,
      dots: false,
      slidesToShow: 5,
      infinite: true,

      //レスポンシブでの動作を指定
      responsive: [{
        breakpoint: 599, //ブレイクポイントを指定
        settings: {
          //arrows: true,
          slidesToShow: 2,
          //slidesToScroll: 1,
        }
      }]
    });
  });

  $(window).on("resize orientationchange", function () {
    $(".fWrap .fBnrS .slide").slick("resize");
  });

  $(function () {
    $('.Main .slide, .List .slide').slick({
      speed: 1000,
      autoplaySpeed: 6000,
      arrows: true,
      autoplay: true,
      dots: true,
      slidesToShow: 1,
      infinite: true,

      //レスポンシブでの動作を指定
      responsive: [{
        breakpoint: 599, //ブレイクポイントを指定
        settings: {
          //arrows: false,
        }
      }]
    });
  });

  $(window).on("resize orientationchange", function () {
    $(".Main .slide, .List .slide").slick("resize");
  });

  $(function () {
    $('.Lifestyle .slide, .Series .slide').slick({
      speed: 1000,
      arrows: true,
      autoplay: false,
      dots: true,
      slidesToShow: 4,
      infinite: false,

      //レスポンシブでの動作を指定
      responsive: [{
        breakpoint: 599, //ブレイクポイントを指定
        settings: {
          //arrows: true,
          slidesToShow: 2,
          //slidesToScroll: 1,
        }
      }]
    });
  });

  $(window).on("resize orientationchange", function () {
    $(".Lifestyle .slide, .Series .slide").slick("resize");
  });

  $(function () {
    $('.Newstyle .slide').slick({
      speed: 1000,
      autoplaySpeed: 6000,
      arrows: true,
      autoplay: true,
      dots: true,
      slidesToShow: 2,
      //infinite: false,

      //レスポンシブでの動作を指定
      responsive: [{
        breakpoint: 599, //ブレイクポイントを指定
        settings: {
          slidesToShow: 1,
        }
      }]
    });
  });

  $(window).on("resize orientationchange", function () {
    $(".Newstyle .slide").slick("resize");
  });


  $(function () {
    $('.Kikaku .Magazinelist .slide, .Architect .Magazinelist .slide').slick({
      speed: 1000,
      autoplaySpeed: 6000,
      arrows: true,
      autoplay: true,
      dots: true,
      slidesToShow: 1,
      //slidesToScroll: 1,
      infinite: true,

      //レスポンシブでの動作を指定
      responsive: [{
        breakpoint: 599, //ブレイクポイントを指定
        settings: 'unslick'
      }]
    });
  });

  $(window).on("resize orientationchange", function () {
    $(".Kikaku .Magazinelist .slide, .Architect .Magazinelist .slide").slick("resize");
  });

  $(function () {
    $('.Kikaku .Image .slide').on('init', function(event, slick) {
      $(this).append('<div class="slick-counter"><span class="counter"></span><span class="total"></span></div>');
      $('.slick-counter .counter').text(slick.currentSlide + 1);
      $('.slick-counter .total').text(slick.slideCount);
    })

    .slick ({
      speed: 1000,
      //autoplaySpeed: 6000,
      arrows: true,
      autoplay: true,
      dots: true,
      slidesToShow: 1,
      infinite: true,

      //レスポンシブでの動作を指定
      responsive: [{
        breakpoint: 599, //ブレイクポイントを指定
        settings: {
          //arrows: false,
        }
      }]
    })

    .on('beforeChange', function(event, slick, currentSlide, nextSlide) {
      $('.counter').text(nextSlide + 1);
    });
  });

  $(window).on("resize orientationchange", function () {
    $(".Kikaku .Image .slide").slick("resize");
  });

  //規格住宅 比較表
  $(function () {
    $('.Kikaku .Compare .slide').slick({
      speed: 1000,
      arrows: true,
      autoplay: false,
      dots: true,
      slidesToShow: 4.5,
      infinite: false,

      //レスポンシブでの動作を指定
      responsive: [{
        breakpoint: 599, //ブレイクポイントを指定
        settings: {
          //arrows: false,
          slidesToShow: 2,
          //slidesToScroll: 1,
        }
      }]
    });
  });

  $(window).on("resize orientationchange", function () {
    $(".Kikaku .Compare .slide").slick("resize");
  });

  $(function () {
    $('.Event .Venue .slide').on('init', function(event, slick) {
      $(this).append('<div class="slick-counter"><span class="counter"></span>｜<span class="total"></span><i class="i_list2" aria-hidden="true"></i></div>');
      $(this).find('.counter').text(slick.currentSlide + 1);
      $(this).find('.total').text(slick.slideCount);
    })

    .slick ({
      speed: 1000,
      autoplaySpeed: 6000,
      arrows: true,
      autoplay: true,
      slidesToShow: 1,
      centerMode: true,
      centerPadding: "17%",

      //レスポンシブでの動作を指定
      responsive: [{
        breakpoint: 599, //ブレイクポイントを指定
        settings: {
          centerMode: false,
          centerPadding: "0%",
        }
      }]
    })

    .on('beforeChange', function(event, slick, currentSlide, nextSlide) {
      $('.counter').text(nextSlide + 1);
    });
  });

  $(window).on("resize orientationchange", function () {
    $(".Event .Venue .slide").slick("resize");
  });

  $(function () {
    $('.Case .Image .slide > ul').on('init', function(event, slick) {
      $(this).append('<div class="slick-counter"><span class="counter"></span>｜<span class="total"></span><i class="i_list2" aria-hidden="true"></i></div>');
      $('.counter').text(slick.currentSlide + 1);
      $('.total').text(slick.slideCount);
    })

    .slick ({
      speed: 1000,
      autoplaySpeed: 6000,
      arrows: true,
      autoplay: true,

      //レスポンシブでの動作を指定
      responsive: [{
        breakpoint: 599, //ブレイクポイントを指定
        settings: {
          arrows: false,
        }
      }]
    })

    .on('beforeChange', function(event, slick, currentSlide, nextSlide) {
      $('.counter').text(nextSlide + 1);
    });

  });

  $(window).on("resize orientationchange", function () {
    $(".Case .Image .slide > ul").slick("resize");
  });

  $(function () {
    //高さ取得 代入
    var h_wrap = $('.Case .Image .slide > ul .img img').height();
    $('.Case .Image .slide .slick-counter').css('top','calc('+h_wrap+'px + 15px)');
    $('.Case .Image .slide .btn').css('top','calc('+h_wrap+'px + 5px)');
  });

  $(function () {
    $('.Product .Image .slide').on('init', function(event, slick) {
      $(this).append('<div class="slick-counter"><span class="counter"></span>｜<span class="total"></span><i class="i_list2" aria-hidden="true"></i></div>');
      $('.counter').text(slick.currentSlide + 1);
      $('.total').text(slick.slideCount);
    })

    .slick ({
      speed: 1000,
      autoplaySpeed: 6000,
      arrows: true,
      autoplay: true,
      //dots: true,
      //slidesToShow: 1,
      //infinite: true,
      asNavFor: ".Product .Image .thumb",

      //レスポンシブでの動作を指定
      responsive: [{
        breakpoint: 599, //ブレイクポイントを指定
        settings: {
          arrows: false,
        }
      }]
    })

    .on('beforeChange', function(event, slick, currentSlide, nextSlide) {
      $('.counter').text(nextSlide + 1);
    });

    $('.Product .Image .thumb').slick({
      //speed: 1000,
      //autoplaySpeed: 6000,
      //arrows: false,
      //autoplay: true,
      //dots: false,
      slidesToShow: 12,
      focusOnSelect: true,
      //infinite: true,
      asNavFor: ".Product .Image .slide",

      //レスポンシブでの動作を指定
      responsive: [{
        breakpoint: 599, //ブレイクポイントを指定
        settings: {
          arrows: false,
          slidesToShow: 6,
          //slidesToScroll: 1,
        }
      }]
    });
  });

  $(window).on("resize orientationchange", function () {
    $(".Product .Image .slide").slick("resize");
  });

  $(function () {
    $('.Product .Type .img .slide').on('init', function(event, slick) {
      $(this).append('<div class="slick-counter"><span class="counter"></span>｜<span class="total"></span><i class="i_list2" aria-hidden="true"></i></div>');
      $('.counter').text(slick.currentSlide + 1);
      $('.total').text(slick.slideCount);
    })

    .slick ({
      speed: 1000,
      autoplaySpeed: 6000,
      arrows: false,
      autoplay: true,
      //dots: true,
      //slidesToShow: 1,
      //infinite: true,

      //レスポンシブでの動作を指定
      responsive: [{
        breakpoint: 599, //ブレイクポイントを指定
        settings: {
          arrows: false,
        }
      }]
    })

    .on('beforeChange', function(event, slick, currentSlide, nextSlide) {
      $('.counter').text(nextSlide + 1);
    });
  });

  $(window).on("resize orientationchange", function () {
    $(".Product .Type .img .slide").slick("resize");
  });


  $(function () {
    $('.Modelhouse .Image .slide').on('init', function(event, slick) {
      $(this).append('<div class="slick-counter"><span class="counter"></span>/<span class="total"></span></div>');
      $('.counter').text(slick.currentSlide + 1);
      $('.total').text(slick.slideCount);
    })

    .slick ({
      speed: 1000,
      autoplaySpeed: 6000,
      arrows: true,
      autoplay: true,
      dots: false,
      slidesToShow: 1,
      variableWidth: true,
      centerMode: true,
      infinite: true,

      //レスポンシブでの動作を指定
      responsive: [{
        breakpoint: 599, //ブレイクポイントを指定
        settings: {
          centerPadding: "24px",
          variableWidth: false,
          //arrows: false,
        }
      }]
    })

    .on('beforeChange', function(event, slick, currentSlide, nextSlide) {
      $('.counter').text(nextSlide + 1);
    });
  });

  $(window).on("resize orientationchange", function () {
    $(".Modelhouse .Image .slide").slick("resize");
  });

  $(function () {
    $('.Modelhouse .Gallery .slide').slick({
      speed: 1000,
      autoplaySpeed: 6000,
      arrows: true,
      autoplay: true,
      asNavFor: ".Modelhouse .Gallery .thumb",

      //レスポンシブでの動作を指定
      responsive: [{
        breakpoint: 599, //ブレイクポイントを指定
        settings: {
          //arrows: false,
        }
      }]
    })

    $('.Modelhouse .Gallery .thumb').slick({
      autoplay: false,
      arrows: false,
      slidesToShow: 6,
      focusOnSelect: true,
      asNavFor: ".Modelhouse .Gallery .slide",

      //レスポンシブでの動作を指定
      responsive: [{
        breakpoint: 599, //ブレイクポイントを指定
        settings: {
        }
      }]
    });
  });

  $(window).on("resize orientationchange", function () {
    $(".Modelhouse .Gallery .slide").slick("resize");
  });

  // modal
  $(function(){

    // 記事投稿
    $('.modal, .swipebox, .gallery__list a').modaal({
      type: 'image',
      overlay_opacity: 0.5
    });

    $('#postCase .swipebox').attr('data-group', 'gallery');
    $('#postMagazine .gallery__list a').attr('data-group', 'gallery');

  });

  $(function(){

    // 商品絞り込み
    $(".modalCont").modaal({
      //content_source: '#searchWrap',
      custom_class: 'modalSearch',
      width: 1200,
      hide_close: true,
      overlay_opacity: 0.5,
      after_callback_delay: 0,
    });

    $('.searchResults .submit, .searchClose').on('click', function () {
      // モーダルを閉じる
      $('.modalCont').modaal('close');
    })

    // ポップアップ
    if ($("main.Mypage .modalBtn").length > 0) {
      $("main.Mypage .modalBtn").modaal({
        custom_class: 'modalPopup',
        width: 440,
        hide_close: true,
        overlay_opacity: 0.5
      });
    }

    // その他
    if ($("main:not(.Mypage) .modalBtn").length > 0) {
      $("main:not(.Mypage) .modalBtn").modaal({
        custom_class: 'modalInline',
        width: 1200,
        hide_close: true,
        overlay_opacity: 0.5
      });
    }

    $('.modalWrap a, .modalClose').on('click', function () {
      // モーダルを閉じる
      $('.modalBtn').modaal('close');
    })

  });

  //$(function(){
  //
  //  if ($(".modalImg").length > 0) {
  //    $(".modalImg").modaal({
  //      custom_class: 'modalTarget',
  //      width: 792,
  //      hide_close: true,
  //      overlay_opacity: 0.5
  //    });
  //  }
  //  $('.modalWrap a, .modalClose').on('click', function () {
  //    // モーダルを閉じる
  //    $('.modalImg').modaal('close');
  //  })
  //
  //});
}(jQuery));

// 2406_Magazine改修
$(document).ready(function () {
  var $slider = $(".MagazineSlide__list");
  var $dotsContainer = $(".MagazineSlide__navigation .dots");

  function moveDots() {
    var $dots = $slider.find(".slick-dots");
    if ($dots.length) {
      $dotsContainer.append($dots); // Append slick-dots to the .dots container
    }
  }

  // 初期化とリサイズイベントの処理を統一
  function initializeSlider() {
    $slider.slick({
      autoplay: true,
      autoplaySpeed: 3000,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      variableWidth: true,
      centerMode: true,
      centerPadding: "0",
      prevArrow: $(".slick-prev"),
      nextArrow: $(".slick-next"),
      responsive: [
        {
          breakpoint: 599,
          settings: {
            slidesToShow: 1,
            variableWidth: false,
            centerMode: false,
          },
        },
      ],
    });

    // 初期化時にdotsを移動
    $slider.on("init reInit", function (event, slick) {
      moveDots();
    });
  }

  $(window).on("resize", function () {
    moveDots(); // リサイズ時にdotsを再度移動
    setTimeout(moveDots, 100); // 確実に再配置を試みるため、少し遅延を持たせて再度実行
  });

  // 初期化
  initializeSlider();

  $(".togglePlayPause").on("click", function () {
    var $button = $(this);
    if ($button.hasClass("pause")) {
      // 自動再生を停止
      $slider.slick("slickPause");
      // ボタンを再生ボタンに切り替え
      $button
        .removeClass("pause")
        .addClass("play")
        .attr("aria-label", "Play the slider")
        .attr("aria-pressed", "true");
    } else {
      // 自動再生を再開
      $slider.slick("slickPlay");
      // ボタンを停止ボタンに切り替え
      $button
        .removeClass("play")
        .addClass("pause")
        .attr("aria-label", "Pause the slider")
        .attr("aria-pressed", "false");
    }
  });

  // 初期化後に一度moveDotsを呼び出してdotsを移動
  moveDots();
});
$(document).ready(function () {
  // メイン画像のオプション
  $("section.Image .slide__list").slick({
    infinite: true,
    // autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    arrows: true,
    asNavFor: "section.Image .thumbnail__list", // サムネイルと同期
  });

  // サムネイルのオプション
  $("section.Image .thumbnail__list").slick({
    asNavFor: "section.Image .slide__list", // メイン画像と同期
    infinite: true,
    slidesToShow: 7,
    arrows: false,
    dots: true,
    focusOnSelect: true, // サムネイルクリックを有効化
    responsive:[
      {
        breakpoint: 600,
        settings:{
          slidesToShow: 4,
        }
      },
    ]
  });

  $('.thumbnail__list .thumbnail__more').on('click', function(event) {
    event.stopPropagation();
    event.preventDefault();
    window.location = '/events'
  });
});

// 2406_ハンバーガー内メニュー開閉
function mediaQueriesWin() {
  let width = $(window).width();
  let isEventPage = window.location.pathname.split("/")[1] === 'events'
  if (width <= 1189 || isEventPage) {
    $(".has-child>a")
      .off("click")
      .on("click", function (event) {
        event.preventDefault(); // リンクのデフォルト動作を停止
        event.stopPropagation(); // 上位へのイベント伝播を停止

        var parentElem = $(this).parent();
        $(parentElem).toggleClass("active");
        $(parentElem).children(".subMenu").stop().slideToggle(500);
        return false;
      });
  } else {
    $(".has-child>a").off("click");
    $(".has-child").removeClass("active");
    $(".has-child").children("ul").css("display", "");
  }
}
$(window).resize(function () {
  mediaQueriesWin(); // ウィンドウリサイズ時に関数を実行
});
$(document).ready(function () {
  mediaQueriesWin(); // ドキュメント読み込み時に関数を実行
});

// 2406_比較するボタン
$(document).ready(function () {
  // すべての「比較する」ボタンにクリックイベントを設定
  $(".check-btn").on("click", function () {
    // クリックされたボタンに 'checked' クラスをトグル（追加/削除）
    $(this).toggleClass("checked");
  });
});

// 2407_TopLineup
$(document).ready(function () {
  // メイン画像のオプション
  $("section.Lineup .collabo__list").slick({
    infinite: false,
    autoplay: false,
    slidesToShow: 3,
    arrows: true,
    dots: true,
    responsive:[
      {
        breakpoint: 600,
        settings:{
          slidesToShow: 2,
        }
      },
    ]
  });
});
