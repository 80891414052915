// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"

Rails.start()

require('select2');
import "channels"

// 以下ベツダイホーム利用しているJSファイル一式
import "src/web/viewport"
require("jquery")
import "src/web/slick.min"
import "src/web/modaal.min"
import "src/web/jquery.fs.boxer.min"
import "src/web/jquery.matchHeight-min"
import "src/web/ofi.min"
import "src/web/run"
import "src/web/fancybox.umd"
import 'src/web/jquery.simplePagination'
import './utils.js'
import { Fancybox } from 'src/web/fancybox.umd'
import LazyLoad from 'src/web/lazyload.min.js'
// import 'swiper/swiper.scss'
import 'bootstrap/js/dist/modal';

window.toastr = require('toastr')
window.Fancybox = Fancybox

toastr.options = {
  positionClass: 'toast-top-center',
  timeOut: 5000,
  preventDuplicates: true
}
// ここまで

window.lazyLoadInstance = new LazyLoad(
  {
    threshold: 100,
    use_native: true,
  }
);
