import Standardized from "../standardized";

var CompareBrand = {
  init: function () {
    if ($('#Content.Kikaku.Compare').length > 0) {
      CompareBrand.handleGetBrand()
      CompareBrand.handleDeleteCompareItem()
    }
  },
  handleGetBrand: function() {
    $('.loadingImage').show()
    $('.slide.bar.compare-item-content').hide()
    let compareBrandIdsJson = localStorage.getItem('compareBrandIds') || '[]'
    let compareBrandIds = JSON.parse(compareBrandIdsJson)
    compareBrandIds.push('')

    $.ajax({
      url: '/standardized/compare_brand',
      type: 'GET',
      dataType: 'json',
      data: { brand_ids: compareBrandIds },
      success: (data) => {
        if (data.html) {
          $('.loadingImage').hide()
          CompareBrand.handleSlickCompareItem(data.html)
          CompareBrand.handleDeleteCompareItem()
          Standardized.handleCreateBrandFavorite()
          Standardized.handleDeleteBrandFavorite()
          CompareBrand.handleSortableCompareItem()
        }
        $('.slide.bar.compare-item-content').show()
        window.lazyLoadInstance.update()
      },
      error: function(XMLHttpRequest, textStatus, errorThrown) {
        console.log('ERROR')
      }
    });
  },
  handleDeleteCompareItem: function () {
    $('.deleteCompareItem').click(function () {
      let compareBrandIdsJson = localStorage.getItem('compareBrandIds') || '[]'
      let compareBrandIds = JSON.parse(compareBrandIdsJson)
      compareBrandIds = compareBrandIds.filter(e => e !== $(this).attr('data-brand-id'))
      localStorage.setItem('compareBrandIds', JSON.stringify(compareBrandIds));
      CompareBrand.handleGetBrand()
    })
  },
  handleSlickCompareItem: function (html) {
    let $slide = $('.Kikaku .Compare .slide')
    $slide.slick('unslick');
    if (html) {
      $('.slide.bar.compare-item-content').html(html)
      Fancybox.bind('.fancybox')
    }
    $slide.slick({
      speed: 300,
      arrows: true,
      autoplay: false,
      dots: true,
      slidesToShow: 4.5,
      infinite: false,
      draggable: false,

      //レスポンシブでの動作を指定
      responsive: [{
        breakpoint: 599, //ブレイクポイントを指定
        settings: {
          //arrows: false,
          slidesToShow: 2,
          //slidesToScroll: 1,
        }
      }]
    });

    // ajxで動的に追加した要素に外部のJSの処理を当てる
    $('.modal').modaal({
      type: 'image',
      overlay_opacity: 0.5
    });

    setTimeout(function () {
      $('[data-mh]').matchHeight({ remove: true });
      $.fn.matchHeight._maintainScroll = true;
      $.fn.matchHeight._applyDataApi();
    }, 1000)

    // マウスホイールでスライドできるように
    // MEMO: 横スライドをさせたくないとの依頼でコメントアウト (https://betsudai.backlog.jp/view/BETSUDAIHOME_RENEWAL-415)
    // $slide.on('wheel', function(e) {
    //   e.preventDefault();
    //   if (!$slide.hasClass('js-slick-moving')) {
    //     if (e.originalEvent.deltaY < 0) {
    //       $(this).slick('slickPrev');
    //     } else {
    //       $(this).slick('slickNext');
    //     }
    //   }
    // })
    // $slide.on('beforeChange', function(){
    //   $slide.addClass('js-slick-moving');
    // });
    // $slide.on('afterChange', function(){
    //   $slide.removeClass('js-slick-moving');
    // });
  },
  handleSortableCompareItem: function() {
    $(".slick-track").sortable()

    // 並び順をCookieにも保存
    $(".slick-track").on("sortupdate", function(){
      var compareBrandIds = $(".slick-track").find(".compareItem").map(function() {
        return $(this).attr('data-brand-id')
      }).toArray()

      if (compareBrandIds) {
        $('.loadingImage').show()
        $('.slide.bar.compare-item-content').hide()
        localStorage.setItem('compareBrandIds', JSON.stringify(compareBrandIds));
        CompareBrand.handleGetBrand()

        setTimeout(function () {
          $('.slide.bar.compare-item-content').show()
          $('.loadingImage').hide()
        }, 200)
      }
    });
  }
}

export default CompareBrand
