import InformationCommon from "../information_common";

var Home = {
  init() {
    if ($('main.Home').length > 0) {
      InformationCommon.showLoadingIcon()
      InformationCommon.getBetsudaiNews(1, 4, 'created_at', 'desc', 2)
      this.initiateMagazineSection()
    }
  },
  initiateMagazineSection(){
    $.ajax({
      url: '/list_magazines',
      type: 'GET',
      dataType: 'json',
      success: function(data) {
        $('ul.Magazine__list').html(data.html)
        window.lazyLoadInstance.update()
      },
      error: function(XMLHttpRequest, textStatus, errorThrown) {
        console.log('ERROR')
      }
    });
  },

}

export default Home
