import { ValidationForm } from '../../src/web/validation_form'

var EventContact = {
  init() {
    if ($("main.Event.theme2").length || $("main.p-sunny").length){
      this.handleValidateFormEventContact()
      this.changeTimeEventReserveDate()
      this.changeLocationName() 
      this.loadDataTimeEventReserveTime()
      this.selectOnlyOneCheckbox()
      this.saveAddress()
      this.handleChangeContentInquiry()
      this.generateGoogleMap()
    }
  },
  handleValidateFormEventContact () {
    $('.btn-validate-form').on('click', function() {
      const ValidateForm = new ValidationForm()
      const isInputValid = ValidateForm.handleValidateInputForm($('.required'))
      const isValidCkContentInquiry = ValidateForm.handleValidateCheckboxForm(
        $('.ck-content-inquiry').find('input:checkbox')
      )
      const isValidCkWayFindOutEvents = ValidateForm.handleValidateCheckboxForm(
        $('.way-find_out-events').find('input:checkbox')
      )
      const isValidPhone = ValidateForm.handleValidatePhone()
      const isValidEmail = ValidateForm.handleValidateEmail()
      const isValidZip = ValidateForm.handleValidateZipcode()
      const acceptPolicy = ValidateForm.handleValidateAcceptPolicy()
      const isValidAddress = ValidateForm.validateAddress($('#event_contact_address'), $('.templory-address-event-contact'))

      let isValidLocationName = true
      let isValidFurigana = true
      let isInputValid2 = true
      let isInputSelectValid = true
      if ($('input:checkbox#event_contact_content_inquiry:first').is(':checked')) {
        isValidLocationName = ValidateForm.handleValidateInputSelectForm(
          $('.select-location:first-child').find('.required-select-location')
        )
      } else {
        isValidFurigana = ValidateForm.handleValidateFurigana()
        isInputValid2 = ValidateForm.handleValidateInputForm2($('.required2'))
        isInputSelectValid = ValidateForm.handleValidateInputSelectForm($('.required-select'))
      }

      const isActive = isInputValid && isInputValid2 && isInputSelectValid && isValidCkContentInquiry
                        && isValidCkWayFindOutEvents && acceptPolicy && isValidEmail
                        && isValidZip && isValidPhone && isValidLocationName && isValidFurigana
                        && isValidAddress
      if (isActive == true) {
        $('#event-contact').submit()
      } else {
        $('html, body').animate({
          scrollTop: $('.validate-form').offset().top
        }, 500);
      }
    })
  },
  changeLocationName () {
    $('.event-location-name').on('change', function() {
      const dateTime = $(this).find(':selected').data('date-time');
      const index = $(this).find(':selected').data('index');
      // reset options of select reserve time
      $(`.event-reserve-date-${index}`).find('option:not(:first)').remove();
      // new options for select reserve time
      for (const date in dateTime) {
        let dateString = date.split('-');
        $('<option>').val(date).attr('data-start-time', dateTime[date]['start_time'])
                     .attr('data-end-time',dateTime[date]['end_time'])
                     .attr('data-index',index)
                     .text(`${dateString[0]}年${dateString[1]}月${dateString[2]}日`)
                     .appendTo(`.event-reserve-date-${index}`);
      }
    })
  },
  changeTimeEventReserveDate () {
    $('.event-reserve-date').on('change', function() {
      const startTime = $(this).find(':selected').data('start-time')
      const endTime = $(this).find(':selected').data('end-time')
      const index = $(this).find(':selected').data('index')
      // reset options of select reserve time
      $(`.event-reserve-time-${index}`).find('option:not(:first)').remove();
      // new options for select reserve time
      if (startTime > 0 && endTime > 0) {
        for(let i=startTime; i <= endTime; i ++) {
          $(`.event-reserve-time-${index}`).append($('<option/>', {
            value: i,
            text: `${i}:00`
          }));
        }
      }
    })
  },

  loadDataTimeEventReserveTime () {
    const inputSelectLocationName = $('.event-location-name')
    for(let i=0; i < inputSelectLocationName.length; i++) {
      const locationNameSelected = $(inputSelectLocationName[i]).find(":selected")
      const indexReserveDate = $(locationNameSelected).data('index')
      const dateTime = $(locationNameSelected).data('date-time')
      const $reserveDateElement = $(`.event-reserve-date-${indexReserveDate}`)
      const valueReserveDateSelected = $reserveDateElement.parent('.required-select-location').data('event-reserve-date-selected')
      // reset options of select reserve time
      $reserveDateElement.find('option:not(:first)').remove();
      // new options for select reserve time
      for (const date in dateTime) {
        let dateString = date.split('-');
        $('<option>').val(date).attr('data-start-time', dateTime[date]['start_time'])
                     .attr('data-end-time',dateTime[date]['end_time'])
                     .attr('data-index',indexReserveDate)
                     .text(`${dateString[0]}年${dateString[1]}月${dateString[2]}日`)
                     .prop('selected', date === valueReserveDateSelected )
                     .appendTo(`.event-reserve-date-${indexReserveDate}`);
      }
    }


    const inputSelectReserveDates = $('.event-reserve-date')
    for(let i=0; i < inputSelectReserveDates.length; i++) {
      const reserveDateSelected = $(inputSelectReserveDates[i]).find(":selected")
      const indexReserveTime = $(reserveDateSelected).data('index')
      const startTime = $(reserveDateSelected).data('start-time')
      const endTime = $(reserveDateSelected).data('end-time')
      const $reserveTimeElement = $(`.event-reserve-time-${indexReserveTime}`)
      const valueReserveTimeSelected = $reserveTimeElement.parent('.required-select-location').data('event-reserve-time-selected')
      // reset options of select reserve time
      $reserveTimeElement.find('option:not(:first)').remove();
      //  new options for select reserve time
      if (startTime > 0 && endTime > 0) {
        for(let i=startTime; i <= endTime; i ++) {
          $('<option>').val(i)
                       .text(`${i}:00`)
                       .prop('selected', i === valueReserveTimeSelected )
                       .appendTo(`.event-reserve-time-${indexReserveTime}`);
        }
      }
    }
  },
  selectOnlyOneCheckbox () {
    $('.ck-content-inquiry').find('input:checkbox').on('click', function() {
      const $box = $(this);
      if ($box.is(":checked")) {
        const group = "input:checkbox[name='" + $box.attr("name") + "']";
        $(group).prop("checked", false);
        $box.prop("checked", true);
      } else {
        $box.prop("checked", false);
      }
    });
  },
  saveAddress () {
    const ValidateForm = new ValidationForm()
    ValidateForm.saveAddress($('.templory-address-event-contact'), $('#event_contact_address'))
  },
  handleChangeContentInquiry() {
    let hideElement = function(_, element) {
      let $element = $(element)
      $element.find('input').val('')
      $element.find('select').val('')
      $element.parent().hide()
    }

    if ($('input:checkbox#event_contact_content_inquiry:first').is(':checked')) {
      $('.wrapper-location').parent().show()

      $('.information-only').each(hideElement)
    } else {
      $('.wrapper-location').each(hideElement)

      $('.information-only').parent().show()
    }

    $('.ck-content-inquiry').find('input:checkbox#event_contact_content_inquiry').on('change', function() {
      if ($(this).val() === 'visit_reserve') {
        $('.wrapper-location').parent().show()

        $('.information-only').each(hideElement)
      } else {
        $('.wrapper-location').each(hideElement)

        $('.information-only').parent().show()
      }
    })
  },
  generateGoogleMap() {
    if ($('.gmap').length > 0) {
      if (typeof window.google == 'undefined' || typeof google.maps == 'undefined') {
        var map_url_obj = { key: 'AIzaSyBt13NjQOsWuthOOcNcYAlsJfPuPQTX-RI', libraries: 'places', region:'JP', language: 'ja', v: '3' }
        var map_url = 'https://maps.googleapis.com/maps/api/js?' + $.param(map_url_obj)
        $.getScript(map_url, function() {
          $('.gmap').each(function() {
            let info_box_element = $(this).closest(".mapWrapper").find('.info-box');
            let position = {lat: $(this).data("lat") ?? 33.233087, lng: $(this).data("lng") ?? 131.606501};
            let content = `
                <p><b>${$(this).data("title")}</b></p>
                <a href='https://maps.google.com/maps?q=${position.lat},${position.lng}' target='blank'>View larger map</a>
              `;
            let map = new google.maps.Map(document.getElementById($(this).attr('id')), {
              center: position,
              zoom: 13,
              disableDefaultUI: true,
              mapTypeId: "roadmap",
            });

            new google.maps.Marker({
              position: position,
              title: $(this).data("title"),
              map
            });
          });
        });
      }
    }
  }
}

export default EventContact
