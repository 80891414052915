var FloorPlan = {
  init() {
    if (!$('main.floor-plan-list').length && !$('main.floor-plan-detail').length && !$('section.Bnumlist').length) {
      return
    };

    FloorPlan.initSlides();
    FloorPlan.handleSearch();
    FloorPlan.handleCloseFilters();
    FloorPlan.handleClearFilters();
    FloorPlan.handleCountTotal();
    FloorPlan.handleToggleFavorite();
    FloorPlan.handleLoadMoreTags();
  },

  initSlides: function () {
    $('.floor-plan-list.Bnum .Image .slide').slick({
      speed: 1000,
      autoplaySpeed: 4000,
      arrows: false, 
      autoplay: true,
      fade: true,

      //レスポンシブでの動作を指定
      responsive: [{
        breakpoint: 599, //ブレイクポイントを指定
        settings: {}
      }]
    });

    $('.floor-plan-detail.Bnum .Type .img .slide').on('init', function(event, slick) {
      $(this).append('<div class="slick-counter"><span class="counter"></span>｜<span class="total"></span><i class="i_list2" aria-hidden="true"></i></div>');
      $('.floor-plan-detail .counter').text(slick.currentSlide + 1);
      $('.floor-plan-detail .total').text(slick.slideCount);
    }).slick ({
      speed: 1000,
      autoplaySpeed: 6000,
      arrows: true, 
      autoplay: true,

      //レスポンシブでの動作を指定
      responsive: [{
        breakpoint: 599, //ブレイクポイントを指定
        settings: {}
      }]
    }).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
      $('.floor-plan-detail .counter').text(nextSlide + 1);
    });
  },

  handleLoadMoreTags: function () {
    $('.Trendkeyword #load-more-tags').click(function () {
      $('.Trendkeyword .more-tags').show();
      $(this).hide();
    })
  },

  handleToggleFavorite: function () {
    $('.floor-plan-list .btn-toggle-favorite, .floor-plan-detail .btn-toggle-favorite, .Bnumlist .btn-toggle-favorite').click(function (e) {
      e.preventDefault();

      let currentElement = $(this);
      let dataChecked = currentElement.data('checked');
      let floorPlanId = currentElement.data('floor-plan-id');

      if (dataChecked == true) {
        FloorPlan.deleteFavorite(currentElement, floorPlanId);
      } else {
        FloorPlan.createFavorite(currentElement, floorPlanId);
      }
    });
  },

  createFavorite: function (currentElement, floorPlanId) {
    $.ajax({
      url: '/floor_plans/favorites',
      method: 'POST',
      data: {
        'floor_plan_id': floorPlanId
      },
      success: function (res) {
        let relatedElementId = currentElement.data('related-element-id');
        currentElement.data('checked', true);
        currentElement.addClass('faved');
        currentElement.parent().addClass('faved');

        if (relatedElementId != '' || relatedElementId != undefined) {
          $(relatedElementId).data('checked', true);
          $(relatedElementId).addClass('faved');
          $(relatedElementId).parent().addClass('faved');
        }
        toastr.success('お気に入りに追加しました。')
      },
      error: function (request, status, error) {
        return toastr.error('エラーが発生しました。');
      }
    });
  },

  deleteFavorite: function (currentElement, floorPlanId) {
    $.ajax({
      url: '/floor_plans/favorites',
      method: 'DELETE',
      data: {
        'floor_plan_id': floorPlanId
      },
      success: function (res) {
        let relatedElementId = currentElement.data('related-element-id');
        currentElement.data('checked', false);
        currentElement.removeClass('faved');
        currentElement.parent().removeClass('faved');

        if (relatedElementId != '' || relatedElementId != undefined) {
          $(relatedElementId).data('checked', false);
          $(relatedElementId).removeClass('faved');
          $(relatedElementId).parent().removeClass('faved');
        }
        toastr.success('お気に入りから削除しました。')
      },
      error: function (request, status, error) {
        return toastr.error('エラーが発生しました。');
      }
    });
  },

  handleCountTotal: function () {
    $('#filters-popup.customize-bs-modal').on('click', "input[type='checkbox']", function (e) {
      FloorPlan.fetchTotal();
    });
  },

  fetchTotal: function () {
    let selectedTags = $('#tags-filter').find('input[type="checkbox"]:checked').map(function() { return $(this).val() }).get();
    let publicMemberChecked = $('.checkbox-public-member').is(':checked');
    let data = {
      'tag_ids[]': selectedTags
    };

    if (publicMemberChecked) {
      data['members_only'] = publicMemberChecked;
    }

    $.ajax({
      url: '/floor_plans/total',
      method: 'GET',
      data: data,
      success: function (res) {
        $('#filters-popup.customize-bs-modal span.brand_count').html(res.total);
      },
      error: function (request, status, error) {
        return toastr.error('エラーが発生しました。');
      }
    });
  },

  handleClearFilters: function () {
    $('#filters-popup.customize-bs-modal').on('click', '.clear-filters', function (e) {
      e.preventDefault();

      $('#filters-popup.customize-bs-modal').find("input[type='checkbox']").prop('checked', false);
      FloorPlan.fetchTotal();
    });
  },

  handleSearch: function () {
    $('#filters-popup.customize-bs-modal').on('click', '#apply-filters', function (e) {
      e.preventDefault();

      FloorPlan.search();
    });

    $('.floor-plan-list').on('change', '#members_only', function(event) {
      event.preventDefault();

      FloorPlan.search();
    });
  },

  search: function () {
    let selectedTags = $('#tags-filter').find('input[type="checkbox"]:checked').map(function() { return $(this).val() }).get();
    let publicMemberChecked = $('.checkbox-public-member').is(':checked');
    let data = {
      'tag_ids[]': selectedTags
    };
    let searchUrl = '/floor_plans?';
    let queryParams = '';

    if (selectedTags.length) {
      selectedTags.forEach(function (tag) {
        queryParams = `${queryParams}tag_ids[]=${tag}&`;
      })
    }

    if (publicMemberChecked) {
      queryParams = `${queryParams}members_only=true&`;
      data['members_only'] = publicMemberChecked;
    }

    const nextURL = `${window.location.origin}/floor_plans?${queryParams}`;
    const nextTitle = document.title;
    const nextState = { search: 'update search result' };
    window.history.replaceState(nextState, nextTitle, nextURL);

    $.ajax({
      url: '/floor_plans',
      method: 'GET',
      data: data,
      beforeSend: function () {
        $('section.List .loading-icon').show();
        $('section.List .inner').hide();
      },
      complete: function () {
        $('section.List .loading-icon').hide();
        $('section.List .inner').show();
      },
      success: function (res) {
        $('section.List').html(res);
        $('#filters-popup span.brand_count').html($('.total span.total-number').data('total'));
        $('section.List .menu, section.List .ul3, section.List .pager').css({
          opacity: 1,
          transform: 'translateY(0px)',
        });
        FloorPlan.closeFiltersModal();
        window.lazyLoadInstance.update();
      },
      error: function (request, status, error) {
        return toastr.error('エラーが発生しました。');
      }
    })
  },

  handleCloseFilters: function() {
    $('.popup').on('click', '.btn-close-floor-plan-filters', function(event) {
      event.preventDefault();

      FloorPlan.closeFiltersModal();
    });
  },

  closeFiltersModal: function () {
    $('#filters-popup').modal('hide');
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
    $('body').removeAttr('style');
  },
}

export default FloorPlan
