export class ValidationForm {
  constructor () {}

  handleValidateInputForm (inputs) {
    let isValid = true
    for(let i=0; i < inputs.length; i++){
      if($(inputs[i]).val().length == 0){
        isValid = false
        $(inputs[i]).addClass('errors')
        const name = $(inputs[i]).data('validate-name')
        const totalInputs = $(inputs[i]).parent().find('input').length
        const totalErrors = $(inputs[i]).parent().find('.errors-messages').children().length
        if (totalErrors < totalInputs ) {
          $(inputs[i]).parent().find('.errors-messages').append(`<label class="alert">${name}を入力してください</label>`)
        }
      } else {
        $(inputs[i]).removeClass('errors')
        $(inputs[i]).parent().find('.errors-messages').html(null)
      }
    }
    return isValid
  }

  handleValidateInputForm2 (inputs) {
    let isValid = true
    for(let i=0; i < inputs.length; i++){
      if($(inputs[i]).val().length == 0){
        isValid = false
        $(inputs[i]).addClass('errors')
        const name = $(inputs[i]).data('validate-name')
        const totalInputs = $(inputs[i]).parent().find('input').length
        const totalErrors = $(inputs[i]).closest('dd').find('.errors-messages2').children().length
        if (totalErrors < totalInputs ) {
          $(inputs[i]).closest('dd').find('.errors-messages2').append(`<label class="alert">${name}を入力してください</label>`)
        }
      } else {
        $(inputs[i]).removeClass('errors')
        $(inputs[i]).closest('dd').find('.errors-messages2').html(null)
      }
    }
    return isValid
  }

  handleValidateInputSelectForm (inputSelects) {
    let isValid = true
    for(let i=0; i < inputSelects.length; i++){
      if($(inputSelects[i]).children().val().length == 0){
        isValid = false
        $(inputSelects[i]).addClass('errors')
        const name = $(inputSelects[i]).children('select').data('validate-name')
        const totalInputs = $(inputSelects[i]).children('select').length
        const totalErrors = $(inputSelects[i]).closest('dd').find('.errors-messages').children().length
        if (totalErrors < totalInputs) {
          $(inputSelects[i]).closest('dd').children('.errors-messages').append(`<label class="alert">${name}を選択してください</label>`)
        }
      } else {
        $(inputSelects[i]).removeClass('errors')
        $(inputSelects[i]).closest('dd').children('.errors-messages').html(null)
      }
    }
    return isValid
  }

  handleValidateCheckboxForm (inputCheckbox) {
    let isValid = false
    for(let i=0; i < inputCheckbox.length; i++){
      if ($(inputCheckbox[i]).is(':checked')) {
        isValid = true
        $(inputCheckbox[i]).closest('.check-box').children('.errors-messages').text(null)
        break
      } else {
        const name = $(inputCheckbox[i]).data('validate-name')
        $(inputCheckbox[i]).closest('.check-box').children('.errors-messages').text(`${name}を選択してください`)
      }
    }
    return isValid
  }

  handleValidateAcceptPolicy () {
    let isValid = false
    const ckPolicy = $('.ck-accept-policy').find('input:checkbox')
    if ($(ckPolicy).is(':checked')) {
      isValid = true
      $(ckPolicy).closest('.check-box').children('.errors-messages').text(null)
    } else {
      $(ckPolicy).closest('.check-box').children('.errors-messages').text('個人情報の取扱規程に同意いただける場合は選択してください')
    }
    return isValid
  }

  handleValidateEmail() {
    let isValid = true
    const emailRegex = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/
    const inputEmail = $('.email')
    if (inputEmail.val().length > 0 && !emailRegex.test(inputEmail.val())) {
      isValid = false
      $(inputEmail).addClass('errors')
      $(inputEmail).parent().children('.errors-messages').html('<label class="alert">フォーマット不正です。再度入力してください。</label>')
    } else if (inputEmail.val().length > 0 && emailRegex.test(inputEmail.val())) {
      $(inputEmail).parent().children('.errors-messages').html(null)
    }
    return isValid
  }

  handleValidateZipcode () {
    let isValid = true
    const zipRegex = /^\d{7}$/
    const inputZip = $('.zipcode')
    if (inputZip.val().length > 0 && !zipRegex.test(inputZip.val())) {
      isValid = false
      $(inputZip).addClass('errors')
      $(inputZip).parent().children('.errors-messages').html('<label class="alert">数字7桁で入力してください。</label>')
    } else if (inputZip.val().length > 0 && zipRegex.test(inputZip.val())) {
      $(inputZip).parent().children('.errors-messages').html(null)
    }
    return isValid
  }

  handleValidatePhone () {
    let isValid = true
    const telRegex = /^\d{8}$|^\d{9}$|^\d{10}$|^\d{11}$/
    const inputTel = $('.phone')
    if (inputTel.val().length > 0 && !telRegex.test(inputTel.val())) {
      isValid = false
      $(inputTel).addClass('errors')
      $(inputTel).parent().children('.errors-messages').html('<label class="alert">数字8桁〜11桁まで入力してください。</label>')
    } else if (inputTel.val() && telRegex.test(inputTel.val())) {
      $(inputTel).parent().children('.errors-messages').html(null)
    }
    return isValid
  }

  handleValidateFurigana () {
    let isValid = true
    const furiganaRegex = /^([ァ-ンヶー-]|[ 　・･])+$/
    const nameKana = $('.furigana')

    if (nameKana.val().length > 0 && !furiganaRegex.test(nameKana.val())) {
        isValid = false
        $(nameKana).addClass('errors')
        $(nameKana).parent().children('.errors-messages').html('<label class="alert">フリガナで入力してください</label>')
    } else if (nameKana.val().length > 0 && furiganaRegex.test(nameKana.val())) {
        $(nameKana).parent().children('.errors-messages').html(null)
    }
    return isValid
  }

  validateAddress (addressElem, temploryAddress) {
    if (addressElem.val().length === 0) {
      addressElem.addClass('errors')
      addressElem.parent().children('.errors-messages').html('<label class="alert">ご住所を入力してください</label>')
      return false
    }
    else if (addressElem.val().length > 0 && addressElem.val() === temploryAddress.val()) {
      addressElem.addClass('errors')
      addressElem.parent().children('.errors-messages').html('<label class="alert">町名以降が入力されていません<br></br>無番地の場合は「無番地」と入力してください</label>')
      return false
    } else if (addressElem.val()) {
      addressElem.removeClass('errors')
      addressElem.parent().children('.errors-messages').html(null)
    }
    return true
  }

  saveAddress (temploryAddressValue, addressValue) {
    if (temploryAddressValue.length > 0) {
      AjaxZip3.onSuccess = function() {
        addressValue.val(temploryAddressValue.val());
      };
    }
  }
}
