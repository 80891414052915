import { ValidationForm } from '../../src/web/validation_form'

var MachinakaContact = {
  init() {
    if ($('#machinaka-contact').length) {
      this.handleValidateFormEventContact()
      this.changeTimeEventVisitDate()
      this.selectOnlyOneCheckbox()
      this.saveAddress()
      this.handleChangeContentInquiry()
      this.handleHistoryBackOpenContactForm()
      this.pushStateBySubmitContact()
    }
  },
  handleValidateFormEventContact () {
    $('.btn-validate-machinaka-contact-form').on('click', function() {
      const ValidateForm = new ValidationForm()
      const isInputValid = ValidateForm.handleValidateInputForm($('.required'))
      const isInputValid2 = ValidateForm.handleValidateInputForm2($('.required2'))
      const isInputSelectValid = ValidateForm.handleValidateInputSelectForm($('.required-select'))
      const isValidCkContentInquiry = ValidateForm.handleValidateCheckboxForm(
        $('.ck-content-inquiry').find('input:checkbox')
      )
      const isValidCkWayFindOutEvents = ValidateForm.handleValidateCheckboxForm(
        $('.way-find_out-events').find('input:checkbox')
      )
      const isValidPhone = ValidateForm.handleValidatePhone()
      const isValidEmail = ValidateForm.handleValidateEmail()
      const isValidZip = ValidateForm.handleValidateZipcode()
      const isValidFurigana = ValidateForm.handleValidateFurigana()
      const acceptPolicy = ValidateForm.handleValidateAcceptPolicy()
      let isValidVisitDateTime = true
      if ($('input:checkbox#machinaka_contact_content_inquiry:first').is(':checked')) {
        isValidVisitDateTime = ValidateForm.handleValidateInputSelectForm(
          $('.select-visit-datetime').find('.required-select-visit-datetime')
        )
      }
      const isValidAddress = ValidateForm.validateAddress($('#machinaka_contact_address'), $('.templory-address-machinaka-contact'))
      const isActive = isInputValid && isInputValid2 && isInputSelectValid && isValidCkContentInquiry
                        && isValidCkWayFindOutEvents && acceptPolicy && isValidEmail
                        && isValidZip && isValidPhone && isValidFurigana && isValidVisitDateTime
                        && isValidAddress
      if (isActive == true) {
        $('#machinaka-contact').submit()
      } else {
        $('html, body').animate({
          scrollTop: $('.validate-form').offset().top
        }, 500);
      }
    })
  },
  changeTimeEventVisitDate () {
    $('#machinaka_contact_visit_date').on('change', function() {
      // reset options of select visit time
      $('#machinaka_contact_visit_time').find('option:not(:first)').remove();
      // new options for select visit time
      // MEMO: まちなかモデルハウスのイベント予約時間は10:00 ~ 17:00 固定
      for(let i=10; i <= 17; i ++) {
        $('#machinaka_contact_visit_time').append($('<option/>', {
          value: `${i}:00`,
          text: `${i}:00`
        }));
      }
    })
  },

  selectOnlyOneCheckbox () {
    $('.ck-content-inquiry').find('input:checkbox').on('click', function() {
      const $box = $(this);
      if ($box.is(":checked")) {
        const group = "input:checkbox[name='" + $box.attr("name") + "']";
        $(group).prop("checked", false);
        $box.prop("checked", true);
      } else {
        $box.prop("checked", false);
      }
    });
  },
  saveAddress () {
    const ValidateForm = new ValidationForm()
    ValidateForm.saveAddress($('.templory-address-machinaka-contact'), $('#machinaka_contact_address'))
  },
  handleChangeContentInquiry() {
    if ($('input:checkbox#machinaka_contact_content_inquiry:first').is(':checked')) {
      $('.wrapper-location').parent().show()
    } else {
      $('.wrapper-location').parent().hide()
      $('.wrapper-location').find('select').val('')
    }

    $('.ck-content-inquiry').find('input:checkbox#machinaka_contact_content_inquiry').on('change', function() {
      if ($(this).val() === 'visit_reserve') {
        $('.wrapper-location').parent().show()
      } else {
        $('.wrapper-location').parent().hide()
        $('.wrapper-location').find('select').val('')
      }
    })
  },

  handleHistoryBackOpenContactForm() {
    let state = window.history.state
    if (state && state.isBackPage) {
      $('.btn9.btn').trigger("click");
      let href = window.location.href
      let prevUrl = href.match(/reserve/)? href : `${href}#reserve`
      setTimeout(() => {
        window.location = prevUrl
      } , 500);
    }

  },

  pushStateBySubmitContact() {
    $('#machinaka-contact').on("submit", function() {
      let href = window.location.href
      let prevUrl = href.match(/reserve/)? href : `${href}#reserve`
      window.history.replaceState({ isBackPage: true }, "", prevUrl)
    })
  },

}

export default MachinakaContact
