var BrandFavorite = {
  init: function() {
    BrandFavorite.handleDeleteBrandFavorite()
    BrandFavorite.handleContactCatalog()
    BrandFavorite.handleCompareBrand()
  },
  handleDeleteBrandFavorite: function() {
    $('.deleteBrandFavorite').click(function (e) {
      e.preventDefault()

      let brand_id = $(this).attr('data-brand-id')
      $.ajax({
        url: '/mypage/destroy_brand_favorite',
        type: 'DELETE',
        dataType: 'json',
        data: { brand_id: brand_id },
        success: function(data) {
          if (data.status === 200) {
            toastr.success('削除に成功しました。')
            $('.favorite-brand-item[data-brand-id="' + brand_id + '"]').remove();
            $('.total.favorite-brand-count span').text($('.favorite-brand-item').length)
          }
        },
        error: function(XMLHttpRequest, textStatus, errorThrown) {
          toastr.error('削除に失敗しました。')
        }
      });
    })
  },
  handleContactCatalog: function() {
    $('.contactCatalogFavorite').click(function (e) {
      e.preventDefault()

      localStorage.removeItem('catalogs_selected')
      localStorage.removeItem('catalogs_selected_count')

      let catalogsSelectedJson = localStorage.getItem('catalogs_selected') || '[]'
      let catalogsSelected = JSON.parse(catalogsSelectedJson)

      $.each($(".favorite-brand-item"), function () {
        let existObj = catalogsSelected.find(item => item.id == $(this).attr('data-catalog-id'))
        if (existObj) { return true }

        if ($(this).attr('data-catalog-id')) {
          let catalogObj = {
            id: $(this).attr('data-catalog-id'),
            name: $(this).data('catalog-name'),
            cover_image_url: $(this).data('catalog-cover-image-url'),
            alt_cover_image: $(this).data('catalog-alt_cover_image')
          }
          catalogsSelected.push(catalogObj)
        }
      })

      localStorage.setItem('catalogs_selected', JSON.stringify(catalogsSelected))
      localStorage.setItem('catalogs_selected_count', catalogsSelected.length)
      window.location.href = '/catalogs/contacts'
    })
  },
  handleCompareBrand: function() {
    $('.compareBrandFavorite').click(function (e) {
      let compareBrandIds = $('.favorite-brand-item').map(function() { return $(this).attr('data-brand-id') }).get()
      localStorage.setItem('compareBrandIds', JSON.stringify(compareBrandIds));
      window.location.href = '/standardized/compare'
    })
  }
}

export default BrandFavorite;
