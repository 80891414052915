import CatalogStarterContact from "../catalog_starter_contact";

const CATALOG_CONTACT_KEY = 'catalog_contact'

var CatalogContact = {
  init() {
    this.setDefaultCatalogsSelectedCount()
    this.setCheckedSelectCatalog()
    this.clearCatalogsSelected()
    this.addCatalogsSelect()
    this.displayListCatalogSelect()
    this.validateCatalogContact()
    this.removeContactFields()
    this.selectProductCatalog() // 規格住宅から遷移時
    this.selectCatalogByUrl()
    CatalogStarterContact.setAttributesContact('#catalog_confirm', CATALOG_CONTACT_KEY)
    CatalogStarterContact.fillContactFields(CATALOG_CONTACT_KEY)
  },
  addCatalogsSelect() {
    $('.select_catalog').on('click', function () {
      let catalogItem = $(this)
      let catalogsSelectedJson = localStorage.getItem('catalogs_selected') || '[]'
      let catalogsSelected = JSON.parse(catalogsSelectedJson)

      let catalogObj = {
        id: catalogItem.attr('data-id'),
        name: catalogItem.attr('data-name'),
        cover_image_url: catalogItem.attr('data-cover_image_url'),
        alt_cover_image: catalogItem.attr('data-alt_cover_image')
      }

      if (catalogItem.is(':checked')) {
        catalogsSelected.push(catalogObj)
      } else {
        catalogsSelected = catalogsSelected.filter(function( item ) {
          return item.id != catalogItem.attr('data-id');
        });
      }

      localStorage.setItem('catalogs_selected', JSON.stringify(catalogsSelected))
      localStorage.setItem('catalogs_selected_count', catalogsSelected.length)

      $('.catalogs_selected_count').each(function () {
        $(this).html(catalogsSelected.length)
      })
    })
  },
  setCheckedSelectCatalog () {
    $('.select_catalog').each(function () {
      let catalogsSelectedJson = localStorage.getItem('catalogs_selected') || '[]'
      let catalogsSelected = JSON.parse(catalogsSelectedJson)
      let checked = catalogsSelected.find(item => item.id == $(this).attr('data-id'))

      if (checked) {
        $(this).prop('checked', true)
      }
    })
  },
  setDefaultCatalogsSelectedCount () {
    let catalogsSelectedCount = localStorage.getItem('catalogs_selected_count') || 0
    $('.catalogs_selected_count').each(function () {
      $(this).html(catalogsSelectedCount)
    })
  },
  clearCatalogsSelected () {
    $('.cancel').on('click', function (e) {
      e.preventDefault();
      localStorage.setItem('catalogs_selected', '[]')
      localStorage.setItem('catalogs_selected_count', '0')

      // MEMO: チェックボックスをすべてオフにする
      let catalogsCheckBoxes = $($('.select_catalog'));
      for(var i = 0; i < catalogsCheckBoxes.length; i++) {
        catalogsCheckBoxes[i].checked = false
      }

      $('.catalogs_selected_count').each(function () {
        $(this).html('0')
      })
    })
  },
  displayListCatalogSelect () {
    let catalogsSelectedJson = localStorage.getItem('catalogs_selected') || '[]'
    let catalogsSelected = JSON.parse(catalogsSelectedJson)
    let catalogsSelectedItem = '';

    catalogsSelected.forEach(function (item) {
      catalogsSelectedItem = `<li>
                                <h4>${item.name}</h4>
                                <figure class="img">
                                <img alt="${item?.alt_cover_image || ''}" src="${item?.cover_image_url}">
                                </figure>
                                <input name="catalog_contact[catalog_ids][]" type="hidden" value="${item.id}">
                              </li>`;

      $('#list_catalog_select').append(catalogsSelectedItem)
    })
  },
  removeContactFields () {
    $('#submit_contact').on('click', function () {
      localStorage.removeItem('catalog_contact')
      localStorage.removeItem('contact_address')
    })

    $('#complete_contact').on('click', function () {
      localStorage.removeItem('catalogs_selected')
      localStorage.removeItem('catalogs_selected_count')
    })
  },
  selectProductCatalog () {
    var params = (new URL(document.location)).searchParams;
    var product_catalog_id = params.get('product_catalog_id');
    if (product_catalog_id) {
      $('.select_catalog').each(function () {
        if ($(this).val() == product_catalog_id) {
          // product_catalog_id と一致するカタログの選択
          if (!$(this).is(':checked')) {
            $(this).click();
          }
        } else {
          // それ以外のカタログは選択を外す
          if ($(this).is(':checked')) {
            $(this).click();
          }
        }
      })
    }
  },
  validateCatalogContact () {
    $('#catalog_confirm').on('click', function (e) {
      if (!CatalogContact.handleCatalogsNotSelected(e)) {
        return false
      }

      if (!CatalogContact.isValid()) {
        $('#alertWrap').removeClass('d-none')
        $('#alertWrap').html('未入力項目があります')

        $('html, body').animate({
          scrollTop: $('#formWrap').offset().top
        }, 500);

        e.preventDefault();
        return false
      }
    })
  },
  isValid () {
    return CatalogStarterContact.isValidStarterCatalogContact()
  },
  handleCatalogsNotSelected (e) {
    let catalogsSelectedJson = localStorage.getItem('catalogs_selected') || '[]'
    let catalogsSelected = JSON.parse(catalogsSelectedJson)

    if (catalogsSelected.length === 0) {
      e.preventDefault();
      $('#alertWrap').removeClass('d-none')
      $('#alertWrap').html('カタログを選択してください。')

      $('html, body').animate({
        scrollTop: $('#formWrap').offset().top
      }, 500);

      return false
    }

    return true
  },
  selectCatalogByUrl: function () {
    const params = new window.URLSearchParams(window.location.search);
    const selectedID = params.get('selected_id')

    if (!selectedID) {
      return
    }

    let catalogsSelectedJson = localStorage.getItem('catalogs_selected') || '[]'
    let catalogsSelected = JSON.parse(catalogsSelectedJson)

    const catalogSelector = $('.List').find(`[data-id="${selectedID}"]`)

    catalogsSelected = catalogsSelected.filter(function( item ) {
      return item.id !== catalogSelector.attr('data-id');
    });

    localStorage.setItem('catalogs_selected', JSON.stringify(catalogsSelected))
    localStorage.setItem('catalogs_selected_count', catalogsSelected.length)

    catalogSelector.prop('checked', false)
    catalogSelector.trigger('click')
  }
}

export default CatalogContact
