const SocialPlugin = {
  init: function () {
    this.shareFacebook()
    this.shareTwitter()
  },
  shareFacebook: function () {
    const shareFBUrl = 'https://www.facebook.com/sharer/sharer.php?u='
    $('.btn-share-facebook').attr('href', shareFBUrl + window.location.href)
  },
  shareTwitter: function () {
    const shareTWUrl = 'https://twitter.com/intent/tweet?url='
    $('.btn-share-twitter').attr('href', shareTWUrl + window.location.href)
  },
}

export default SocialPlugin
