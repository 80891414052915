var Standardized = {
  init: function() {
    Standardized.reloadWebFont()
    Standardized.handleCreateBrandFavorite()
    Standardized.handleDeleteBrandFavorite()
    Standardized.handleBrandSearch()
    Standardized.submitBrandSearch() // リロード時に実行
    Standardized.handleCompareProduct()
  },
  reloadWebFont: function() {
    // スライダーなどのWebFontが読み込まれないページでリロードする
    if ($('#webfont-reload').val() == 'true') {
      FONTPLUS.reload(true)
    }
  },

  handleCreateBrandFavorite: function() {
    $('.createStandardizedBrandFavorite').click(function (e) {
      e.preventDefault()
      var brand_id = $(this).attr('data-brand-id')

      $.ajax({
        url: '/standardized/create_brand_favorite',
        type: 'POST',
        dataType: 'json',
        data: { brand_id: brand_id },
        success: (data) => {
          if (data && data.status === 200) {
            toastr.success('商品を登録しました。')
            $('.deleteBrandFavoriteForm[data-brand-id="' + brand_id + '"]').show();
            $('.createBrandFavoriteForm[data-brand-id="' + brand_id + '"]').hide();
          } else {
            toastr.error('登録に失敗しました。')
          }
        },
        error: function(XMLHttpRequest, textStatus, errorThrown) {
          if (XMLHttpRequest.status == 401) {
            window.location.href = '/members'
          } else {
            toastr.error('登録に失敗しました。')
          }
        }
      });
    })
  },
  handleDeleteBrandFavorite: function() {
    $('.deleteStandardizedBrandFavorite').click(function (e) {
      e.preventDefault()
      var brand_id = $(this).attr('data-brand-id')

      $.ajax({
        url: '/standardized/destroy_brand_favorite',
        type: 'DELETE',
        dataType: 'json',
        data: { brand_id: brand_id },
        success: (data) => {
          if (data && data.status === 200) {
            toastr.success('商品を削除しました。')
            $('.deleteBrandFavoriteForm[data-brand-id="' + brand_id + '"]').hide();
            $('.createBrandFavoriteForm[data-brand-id="' + brand_id + '"]').show();
          } else {
            toastr.error('削除に失敗しました。')
          }
        },
        error: function(XMLHttpRequest, textStatus, errorThrown) {
          toastr.error('削除に失敗しました。')
        }
      });
    })
  },
  handleBrandSearch: function() {
    $('#submitBrandSearch').click(function (e) {
      Standardized.updateBrandResult()
      Standardized.updateBrandResultCount()
      Standardized.handleUpdateBrandModalCount()
    })
  },
  updateBrandResult: function() {
    let filteredBrandIds = Standardized.filterAndGetBrandIds()
    // MEMO: 規格住宅TOP用
    let brandResultItemsIndex = $.merge($('#brandResultIndex').find('.brand_result_item'),
                                        $('#brandCollaborationResult').find('.collabo__item'));
    Standardized.updateBrandResultWithBrandIds(brandResultItemsIndex, filteredBrandIds)
  },
  filterAndGetBrandIds: function() {
    const selectedStyleCategories = $('#searchCondStyleCategory').find('input[type="checkbox"]:checked').map(function() { return $(this).val() }).get();
    const selectedLayouts = $('#searchCondLayout').find('input[type="checkbox"]:checked').map(function() { return $(this).val() }).get();
    const selectedFloors = $('#searchCondFloor').find('input[type="checkbox"]:checked').map(function() { return $(this).val() }).get();
    const selectedTags = $('#searchCondTag').find('input[type="checkbox"]:checked').map(function() { return $(this).val() }).get();
    const mainBrandId = $('#searchCondMainBrandId').val()
    let brands = $.merge($('#brandResultIndex').find('.brand_result_item'), $('#brandCollaborationResult').find('.collabo__item'));
    // filter
    // MEMO: 規格住宅TOP用
    // MEMO: 規格住宅一覧用
    if ($(selectedStyleCategories)?.length != 0) { brands = brands.filter((_,b) => selectedStyleCategories.includes($(b).data('style-category'))) }
    if ($(selectedLayouts)?.length != 0) { brands = brands.filter((_,b) => selectedLayouts.includes($(b).data('layout'))) }
    if ($(selectedFloors)?.length != 0) { brands = brands.filter((_,b) => selectedFloors.includes($(b).data('floor'))) }
    if ($(selectedTags)?.length != 0) { brands = brands.filter((_,b) => Standardized.getIsDuplicate(selectedTags, $(b).data('tags'))) }
    if (mainBrandId) { brands = brands.filter((_,b) => $(b).data('main-brand-id') == mainBrandId) }

    var brandIds = brands.map(function() {
      return $(this).data('id');
    }).get();
    return brandIds
  },
  getIsDuplicate: function(arr1, arr2) {
    return [...arr1, ...arr2].filter(item => arr1.includes(item) && arr2.includes(item)).length > 0
  },
  updateBrandResultWithBrandIds: function(brandItems, brandIds) {
    $(brandItems).each(function () {
      if (brandIds.includes($(this).data('id'))) {
        $(this).show();
      } else {
        $(this).hide();
      }
    })
  },
  updateBrandResultCount: function() {
    let $list_section = $('section.List')
    let $collabo_section = $('section.collabo')
    $list_section.show()
    $collabo_section.show()

    // MEMO: 規格住宅TOP用
    let count = $('#brandResultIndex').find('.brand_result_item:visible')?.length
    if (count === 0) {
      $list_section.hide()
    }
    $('#brandResultCount').html(count)

    count = $('#brandCollaborationResult').find('.collabo__item:visible')?.length
    if (count === 0) {
      $collabo_section.hide()
    }
    $('#brandCollaborationResultCount').html(count)
  },
  handleUpdateBrandModalCount: function() {
    $('.brandModalCheckbox').click(function (e) {
      var brandIds = Standardized.filterAndGetBrandIds()
      var count = (brandIds) ? brandIds.length : 0
      $('#brandModalCount').html(count)
    })
    $('.clearBrandModalCheck').click(function (e) {
      Standardized.clearBrandCheck();
      var brandIds = Standardized.filterAndGetBrandIds()
      var count = (brandIds) ? brandIds.length : 0
      $('#brandModalCount').html(count)
    })
  },
  clearBrandCheck: function() {
    $('#brandSearchForm').find("input[type='checkbox']").prop('checked', false);
    $('#searchCondMainBrandId').val('');
  },
  submitBrandSearch: function() {
    setTimeout(function() {
      // ブラウザバック時にチェックボックスがONになるタイムラグがあるので500ms後に実行
      $('#submitBrandSearch').click();
    }, 500);
  },
  handleCompareProduct: function() {
    let compareBrandIdsJson = localStorage.getItem('compareBrandIds') || '[]'
    let compareBrandIds = JSON.parse(compareBrandIdsJson)

    $('.brands_selected_count').text(compareBrandIds.length)
    $.each($('.c-check-wrap .check-btn.checkBox.R'), function() {
      if (compareBrandIds.includes($(this).attr("data-value"))) {
        $(this).addClass('checked')
      } else {
        $(this).removeClass('checked')
      }
    })

    $('.c-check-wrap .check-btn.checkBox.R').click(function(e) {
      compareBrandIdsJson = localStorage.getItem('compareBrandIds') || '[]'
      compareBrandIds = JSON.parse(compareBrandIdsJson)

      if($(this).hasClass('checked')) {
        compareBrandIds.push($(this).attr("data-value"))
        localStorage.setItem('compareBrandIds', JSON.stringify(compareBrandIds));

        $('.brands_selected_count').text(compareBrandIds.length)
      } else {
        compareBrandIds = compareBrandIds.filter(e => e !== $(this).attr("data-value"))
        localStorage.setItem('compareBrandIds', JSON.stringify(compareBrandIds));

        $('.brands_selected_count').text(compareBrandIds.length)
      }
    });

    $('.removeAllCompare').click(function (e) {
      e.preventDefault()
      $.each($('.c-check-wrap .check-btn.checkBox.R'), function() {
        $(this).removeClass('checked')
      })

      localStorage.removeItem('compareBrandIds')
      $('.brands_selected_count').text(0)
    })

    $(".selectAllBrandCatalog").click(function (e) {
      e.preventDefault()

      localStorage.removeItem('catalogs_selected')
      localStorage.removeItem('catalogs_selected_count')

      let catalogsSelectedJson = localStorage.getItem('catalogs_selected') || '[]'
      let catalogsSelected = JSON.parse(catalogsSelectedJson)

      $.each($(".selectBrandCatalogBottom"), function () {
        let existObj = catalogsSelected.find(item => item.id == $(this).attr('data-catalog-id'))
        if (existObj) { return true }

        if ($(this).attr('data-catalog-id')) {
          let catalogObj = {
            id: $(this).attr('data-catalog-id'),
            name: $(this).data('catalog-name'),
            cover_image_url: $(this).data('catalog-cover-image-url'),
            alt_cover_image: $(this).data('catalog-alt_cover_image')
          }
          catalogsSelected.push(catalogObj)
        }
      })

      localStorage.setItem('catalogs_selected', JSON.stringify(catalogsSelected))
      localStorage.setItem('catalogs_selected_count', catalogsSelected.length)
      window.location.href = $(this).attr('href')
    })
  }
}

export default Standardized
