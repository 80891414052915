import {ValidationForm} from "../../src/web/validation_form";
import Contact from "./catalogs/contact";

var CatalogStarterContact = {
    init() {
        this.saveAddress()
    },
    isValidStarterCatalogContact() {
        const ValidateForm = new ValidationForm()

        const isInputValid = ValidateForm.handleValidateInputForm($('.required'))
        const isInputValid2 = ValidateForm.handleValidateInputForm2($('.required2'))
        const isInputSelectValid = ValidateForm.handleValidateInputSelectForm($('.required-select'))
        const isValidPhone = ValidateForm.handleValidatePhone()
        const isValidEmail = ValidateForm.handleValidateEmail()
        const isValidZip = ValidateForm.handleValidateZipcode()
        const isValidFurigana = ValidateForm.handleValidateFurigana()
        const acceptPolicy = ValidateForm.handleValidateAcceptPolicy()
        const isValidAddress = CatalogStarterContact.validateAddress()

        const isActive = isInputValid && isInputValid2 && isInputSelectValid && acceptPolicy && isValidEmail && isValidAddress
                            && isValidZip && isValidPhone && isValidFurigana

        return isActive
    },
    setAttributesContact (btnID, contactKey) {
        $(btnID).on('click', function (e) {
            let contact = {
                catalog_contact: {
                    name: $('#catalog_contact_name').val(),
                    name_kana: $('#catalog_contact_name_kana').val(),
                    zipcode: $('#catalog_contact_zipcode').val(),
                    address: $('#catalog_contact_address').val(),
                    tel: $('#catalog_contact_tel').val(),
                    email: $('#catalog_contact_email').val(),
                    receive_mail_flag: $('#catalog_contact_receive_mail_flag').val(),
                    special_notice: $('#catalog_contact_special_notice').val(),
                    accept_privacy: $('#catalog_contact_accept_privacy').is(':checked'),
                    plan_construction_site: $('#catalog_contact_plan_construction_site').val(),
                    plan_construction_city: $('#catalog_contact_plan_construction_city').val(),
                }
            }

            localStorage.setItem(contactKey, JSON.stringify(contact))
        })
    },
    fillContactFields (contactKey) {
        let contactFieldsJson = localStorage.getItem(contactKey)
        let contactFields = JSON.parse(contactFieldsJson)
        let currentUrl = window.location.href

        if (contactKey === 'starter_contact' && !currentUrl.includes('starter')) {
            return
        }

        if (contactKey === 'catalog_contact' && !currentUrl.includes('catalog')) {
            return
        }

        if (contactFields) {
            contactFields = contactFields.catalog_contact
            if ($('#catalog_contact_name').val()?.length === 0) {
                $('#catalog_contact_name').val(contactFields.name)
            }

            if ($('#catalog_contact_name_kana').val()?.length === 0) {
                $('#catalog_contact_name_kana').val(contactFields.name_kana)
            }

            if ($('#catalog_contact_zipcode').val()?.length === 0) {
                $('#catalog_contact_zipcode').val(contactFields.zipcode)
            }

            if ($('#catalog_contact_address').val()?.length === 0) {
                $('#catalog_contact_address').val(contactFields.address)
            }
            if ($('#catalog_contact_tel').val()?.length === 0) {
                $('#catalog_contact_tel').val(contactFields.tel)
            }
            if ($('#catalog_contact_email').val()?.length === 0) {
                $('#catalog_contact_email').val(contactFields.email)
            }

            if (contactFields.receive_mail_flag?.length !== 0) {
                $('#catalog_contact_receive_mail_flag').val(contactFields.receive_mail_flag)
            }

            if ($('#catalog_contact_special_notice').val()?.length === 0) {
                $('#catalog_contact_special_notice').val(contactFields.special_notice)
            }
            if ($('#catalog_contact_plan_construction_site').val()?.length === 0) {
                const default_site = contactFields.plan_construction_site || '大分県'
                $('#catalog_contact_plan_construction_site').val(default_site)
            }
            if ($('#catalog_contact_plan_construction_city').val()?.length === 0) {
                $('#catalog_contact_plan_construction_city').val(contactFields.plan_construction_city)
            }
            if (contactFields.accept_privacy) {
                $('#catalog_contact_accept_privacy').prop('checked', true)
            }
        } else {
            if ($('#catalog_contact_plan_construction_site').val()?.length === 0) {
                $('#catalog_contact_plan_construction_site').val('大分県')
            }
        }
    },
    validateAddress () {
        let addressElem = $('#catalog_contact_address')
        if (addressElem.val().length === 0) {
            addressElem.addClass('errors')
            addressElem.parent().children('.errors-messages').html('<label class="alert">ご住所を入力してください</label>')
            return false
        }
        else if (addressElem.val().length > 0 && addressElem.val() === $('.templory-address-catalog-contact').val()) {
            addressElem.addClass('errors')
            addressElem.parent().children('.errors-messages').html('<label class="alert">町名以降が入力されていません<br></br>無番地の場合は「無番地」と入力してください</label>')
            return false
        } else if (addressElem.val()) {
            addressElem.parent().children('.errors-messages').html(null)
        }

        return true
    },
    saveAddress () {
        if ($('#catalog_contact_address').length > 0) {
            AjaxZip3.onSuccess = function() {
                $('#catalog_contact_address').val($('.templory-address-catalog-contact').val());
            };
        }
    }
}

export default CatalogStarterContact
