var SunnyTrack = {
  init() {
    if (!$('main.p-sunny').length) {
      return
    }
    $('.js-modaal-image').modaal({ type: 'image' });

    window.onload = () => {
      SunnyTrack.initIframe();
    };
  },

  initIframe: function () {
    $('.iframe-container').each(function() {
      let src = $(this).data('src');
      let style = $(this).data('style');
      $(this).html(`<iframe src="${src}" style="${style}"></iframe>`);
    });
  },
}

export default SunnyTrack;